import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/AdminJwtService";
import { Actions, Mutations } from "@/store/enums/AdminStoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Timezone {
  id: string;
  code: string;
  name: string;
  status: string;
}

export interface Country {
  id: string;
  code: string;
  name: string;
  status: string;
}

export interface Profession {
  id: string;
  name: string;
  status: string;
}

export interface EventCategory {
  id: string;
  name: string;
  status: string;
}

export interface EventEnvironment {
  id: string;
  name: string;
  code: string;
  image: string;
  status: string;
}

export interface UserRole {
  id: string;
  role: string;
  name: string;
}

export interface Space {
  id: string;
  code: string;
  name: string;
  zones_count: string;
}

export interface Zone {
  id: string;
  code: string;
  name: string;
  banners_count: string;
}

export interface CampaignsReg {
  name: string;
  email: string;
  mobile: string;
  city: string;
  created_at: string;
  current_status: string;
}


export interface MailTemplate {
  mail_content: string;
  mail_type: string;
  id: string;
}
export interface Banner {
  id: string;
  code: string;
  name: string;
  zone: unknown;
}

export interface Resource {
  id: string;
  name: string;
  type: string;
  file_url: string;
}

export interface Portal {
  id: string;
  code: string;
  name: string;
}

export interface MasterInfo {
  errors: unknown;
  timezones: Timezone[];
  countries: Country[];
  professions: Profession[];
  profession: Profession;
  eventCategories: EventCategory[];
  eventCategory: EventCategory;
  eventEnvironments: EventEnvironment[];
  userRoles: UserRole[];
  spaces: Space[];
  space: Space;
  zones: Zone[];
  zone: Zone;
  mailTemplate: MailTemplate[];
  campaignsReg: CampaignsReg[];
  banners: Banner[];
  resources: Resource[];
  isLoading: boolean;
  message: string;
  meta: unknown;
  portals: Portal[];
  portal: Portal;
}

@Module({
  namespaced: true,
})
export default class AdminMasterModule extends VuexModule implements MasterInfo {
  errors = {};
  timezones = {} as Timezone[];
  countries = {} as Country[];
  professions = {} as Profession[];
  profession = {} as Profession;
  eventCategories = {} as EventCategory[];
  eventCategory = {} as EventCategory;
  eventEnvironments = {} as EventEnvironment[];
  userRoles = {} as UserRole[];
  spaces = {} as Space[];
  space = {} as Space;
  zones = {} as Zone[];
  zone = {} as Zone;
  banners = {} as Banner[];
  resources = {} as Resource[];
  isLoading = false;
  message = "";
  meta = {};
  campaignsReg = {} as CampaignsReg[];
  mailTemplate = {} as MailTemplate[];
  portals = {} as Portal[];
  portal = {} as Portal;

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }
  
  /**
   * Get meta
   * @returns array
   */
  get getMeta() {
    return this.meta;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  @Mutation
  [Mutations.SET_META](meta) {
    this.meta = meta;
  }

  /**
   * Get timezones
   * @returns array
   */
  get getTimezones() {
    return this.timezones;
  }

  /**
   * Get countries
   * @returns array
   */
  get getCountries() {
    return this.countries;
  }

  /**
   * Get professions
   * @returns array
   */
   get getProfessions() {
    return this.professions;
  }

  /**
   * Get profession
   * @returns array
   */
   get getProfession() {
    return this.profession;
  }

  /**
   * Get eventCategories
   * @returns array
   */
   get getEventCategories() {
    return this.eventCategories;
  }

  /**
   * Get getEventCategory
   * @returns array
   */
   get getEventCategory() {
    return this.eventCategory;
  }

  /**
   * Get eventEnvironments
   * @returns array
   */
   get getEventEnvironments() {
    return this.eventEnvironments;
  }

  /**
   * Get userRoles
   * @returns array
   */
   get getUserRoles() {
    return this.userRoles;
  }

  /**
   * Get getSpaces
   * @returns array
   */
  get getSpaces() {
    return this.spaces;
  }
  
  /**
   * Get getZones
   * @returns array
   */
   get getZones() {
    return this.zones;
  }
  
  /**
   * Get getPortals
   * @returns array
   */
   get getPortals() {
    return this.portals;
  }

  
  /**
   * Get getCampaignReg
   * @returns array
   */
   get getCampaignReg() {
    return this.campaignsReg;
  }

   /**
   * Get getMailTemplates
   * @returns array
   */
    get getMailTemplates() {
      return this.mailTemplate;
    }
    /**
     * Get getSpace
     * @returns array
     */
     get getSpace() {
      return this.space;
    }
  
  /**
   * Get getZone
   * @returns array
   */
   get getZone() {
    return this.zone;
  }

  /**
   * Get getPortal
   * @returns array
   */
   get getPortal() {
    return this.portal;
  }

  /**
   * Get getBanners
   * @returns array
   */
   get getBanners() {
    return this.banners;
  }

  /**
   * Get getResources
   * @returns array
   */
   get getResources() {
    return this.resources;
  }

  @Mutation
  [Mutations.SET_TIMEZONES](data) {
    this.timezones = data;
  }

  @Mutation
  [Mutations.SET_COUNTRIES](data) {
    this.countries = data;
  }

  @Mutation
  [Mutations.SET_PROFESSIONS](data) {
    this.professions = data;
  }

  @Mutation
  [Mutations.SET_PROFESSION](data) {
    this.profession = data;
  }

  @Mutation
  [Mutations.SET_EVENT_CATEGORIES](data) {
    this.eventCategories = data;
  }

  @Mutation
  [Mutations.SET_EVENT_CATEGORY](data) {
    this.eventCategory = data;
  }

  @Mutation
  [Mutations.SET_EVENT_ENVIRONMENTS](data) {
    this.eventEnvironments = data;
  }

  @Mutation
  [Mutations.SET_USER_ROLES](data) {
    this.userRoles = data;
  }

  @Mutation
  [Mutations.SET_SPACES](data) {
    this.spaces = data;
  }

  @Mutation
  [Mutations.SET_SPACE](data) {
    this.space = data;
  }

  @Mutation
  [Mutations.SET_ZONES](data) {
    this.zones = data;
  }

  @Mutation
  [Mutations.SET_ZONE](data) {
    this.zone = data;
  }

  @Mutation
  [Mutations.SET_CAMPAIGNS_REG](data) {
    this.campaignsReg = data;
  }
  
  @Mutation
  [Mutations.SET_MAIL_TEMPLATE](data) {
    this.mailTemplate = data;
  }

  @Mutation
  [Mutations.SET_BANNERS](data) {
    this.banners = data;
  }

  @Mutation
  [Mutations.SET_RESOURCES](data) {
    this.resources = data;
  }

  @Mutation
  [Mutations.SET_PORTALS](data) {
    this.portals = data;
  }

  @Action
  [Actions.FETCH_TIMEZONES](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/timezones", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TIMEZONES, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_COUNTRIES](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/countries", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTRIES, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_PROFESSIONS](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/professions", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROFESSIONS, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
  
  @Action
  [Actions.STORE_PROFESSION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/professions", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_PROFESSION](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/master/professions/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_PROFESSION](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/professions/"+id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.FETCH_EVENT_CATEGORIES](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/event-categories", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EVENT_CATEGORIES, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
  
  @Action
  [Actions.STORE_EVENT_CATEGORY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/event-categories", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.GET_EVENT_CATEGORY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/master/event-categories/"+payload.id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_EVENT_CATEGORY, data.data);
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_EVENT_CATEGORY](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/master/event-categories/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_EVENT_CATEGORY](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/event-categories/"+id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.FETCH_EVENT_ENVIRONMENTS](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/event-environments", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EVENT_ENVIRONMENTS, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.STORE_EVENT_ENVIRONMENT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/event-environments", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_EVENT_ENVIRONMENT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/event-environments/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.FETCH_USER_ROLES](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/user-roles", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ROLES, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_ALL_SPACES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/master/spaces/all")
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data.message);
      });
    });
  }
  
  @Action
  [Actions.FETCH_SPACES](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/spaces", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SPACES, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
  
  @Action
  [Actions.STORE_SPACE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/spaces", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.GET_SPACE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/master/spaces/"+payload.id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SPACE, data.data);
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_SPACE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/master/spaces/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_SPACE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/spaces/"+id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.FETCH_ALL_ZONES]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/master/zones/all")
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data.message);
      });
    });
  }
  
  @Action
  [Actions.FETCH_ZONES](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/zones", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ZONES, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
  
  @Action
  [Actions.STORE_ZONE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/zones", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.GET_ZONE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/master/zones/"+payload.id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_ZONE, data.data);
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_ZONE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/master/zones/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_ZONE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/zones/"+id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.FETCH_ALL_BANNERS](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/master/zones/"+id+"/banners/all")
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data.message);
      });
    });
  }
  
  @Action
  [Actions.FETCH_BANNERS](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/zones/"+payload.zoneID+"/banners", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_BANNERS, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.STORE_BANNER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/zones/"+payload.zoneID+"/banners", payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_BANNER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/master/zones/"+payload.zoneID+"/banners/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_BANNER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/zones/"+payload.zoneID+"/banners/"+payload.bannerID)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.FETCH_RESOURCES](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/resources", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESOURCES, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
  
  @Action
  [Actions.STORE_RESOURCE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/resources", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_RESOURCE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/master/resources/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_RESOURCE](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/resources/"+id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.STORE_RESOURCE_BANNER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/resources/"+payload.resourceID+"/banners", payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_RESOURCE_BANNER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/resources/"+payload.resourceID+"/banners/"+payload.bannerID)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }


  @Action
  [Actions.FETCH_CAMPAIGNS_REG](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.post(url ?? "admin/campaigns-reg", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAMPAIGNS_REG, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  
  @Action
  [Actions.FETCH_MAIL_TEMPLATES](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.post(url ?? "admin/campaign-mail-templates", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MAIL_TEMPLATE, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  
  @Action
  [Actions.UPDATE_MAIL_TEMPLATE](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const url = payload.url ? payload.url : null;
      ApiService.post(url ?? "admin/mail-template-update", payload as AxiosRequestConfig)
      .then(({ data }) => {
        // this.context.commit(Mutations.SET_MAIL_TEMPLATE, data.data);
        // this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.GET_MAIL_TEMPLATE](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const url = payload.url ? payload.url : null;
      ApiService.post(url ?? "admin/get-mail-template", payload as AxiosRequestConfig)
      .then(({ data }) => {
        // this.context.commit(Mutations.SET_MAIL_TEMPLATE, data.data);
        // this.context.commit(Mutations.SET_META, data.meta);
        resolve(data.data);
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
  @Action
  [Actions.FETCH_ALL_PORTALS]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/master/portals/all")
      .then(({ data }) => {
        resolve(data.data);
      })
      .catch(({ response }) => {
        reject(response.data.message);
      });
    });
  }
  
  @Action
  [Actions.FETCH_PORTALS](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/master/portals", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PORTALS, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
  
  @Action
  [Actions.STORE_PORTAL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/portals", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.GET_PORTAL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/master/portals/"+payload.id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_PORTAL, data.data);
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_PORTAL](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/master/portals/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_PORTAL](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/portals/"+id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.STORE_PORTAL_EVENT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/master/portals/"+payload.portalID+"/events", payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_PORTAL_EVENT](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/master/portals/"+payload.portalID+"/events/"+payload.eventID)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }
}
