enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  EMAIL_VERIFICATION_NOTIFICATION = "emailVerificationNotification",
  LOGIN = "login",
  SOCIAL_AUTH = "socialAuth",
  SOCIAL_LINK_LOGIN = "socialLinkLogin",
  SOCIAL_LINK_SIGNUP = "socialLinkSignUp",
  PURGE_AUTH = "purgeAuth",
  LOGOUT = "logout",
  REGISTER = "register",
  CAMPAIGNREGFORM = "event-registration",
  CAMPAIGNREGFORMLOAD = "event-reg-load",
  FETCH_PROFILE = "fetchProfile",
  UPDATE_PROFILE = "updateProfile",
  CHANGE_PASSWORD = "changePassword",
  FETCH_CONFIG = "fetchConfig",

  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  FETCH_EVENTS = "fetchEvents",
  FETCH_MY_EVENTS = "fetchMyEvents",
  FETCH_EVENT_USERS = "fetchEventUsers",
  FETCH_MY_EVENTS_HISTORY = "fetchMyEventsHistory",
  STORE_EVENT = "storeEvent",
  UPDATE_EVENT = "updateEvent",
  UPDATE_PAYMENT= "updatePayment",
  FETCH_TIMEZONES = "fetchTimezones",
  FETCH_COUNTRIES = "fetchCountries",
  FETCH_PROFESSIONS = "fetchProfessions",
  FETCH_EVENT_CATEGORIES = "fetchEventCategories",
  FETCH_EVENT_ENVIRONMENTS = "fetchEventEnvironments",
  FETCH_USER_ROLES = "fetchUserRoles",
  FETCH_PLANS = "fetchPlans",
  FETCH_CHECKOUT = "fetchCheckout",
  FETCH_MY_SUBSCRIPTION="fetchMySubscription",
  CANCEL_MY_SUBSCRIPTION="cancelMySubscription",
  FETCH_MY_SUBSCRIPTION_PORTAL_URL="fetchMySubscriptionPortalURL",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_SOCIAL_AUTH_PROVIDER = "setSocialAuthProvider",
  SET_SOCIAL_AUTH_PROVIDER_UID = "setSocialAuthProviderUID",
  SET_SOCIAL_AUTH_TOKEN = "setSocialAuthToken",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_META = "setMeta",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_EVENTS = "setEvents",
  SET_EVENT_USERS = "setEventUsers",
  SET_EVENTS_HISTORY = "setEventsHistory",
  SET_EVENT_DETAILS_INDEX = "setEventDetailsIndex",
  SET_CONFIG = "setConfig",
  
  SET_SUCCESS_MESSAGE = "setSuccessMessage",
  SET_IS_LOADING = "setIsLoading",
  REMOVE_BODY_CLASSNAMES = "removeBodyClassNames",

  SET_TIMEZONES = "setTimezones",
  SET_COUNTRIES = "setCountries",
  SET_PROFESSIONS = "setProfessions",
  SET_EVENT_CATEGORIES = "setEventCategories",
  SET_EVENT_ENVIRONMENTS = "setEventEnvironments",
  SET_USER_ROLES = "setUserRoles",
  SET_PLANS = "setPlans",
  SET_CHECKOUT = "setCheckout",
  SET_MY_SUBSCRIPTION="setMySubscription",
  SET_MY_SUBSCRIPTION_PORTAL_URL="setMySubscriptionPortalURL",

}

enum Modules {
  // module types
  APP = "AppModule/",
  MASTER = "MasterModule/",
  EVENT = "EventModule/",
  PLAN = "PlanModule/",
}

export { Actions, Mutations, Modules };
