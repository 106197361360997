import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Config {
  id: string;
  role: string;
  name: string;
}

export interface AppInfo {
  errors: unknown;
  config: Config;
  isLoading: boolean;
  message: string;
}

@Module({
  namespaced: true,
})
export default class AppModule extends VuexModule implements AppInfo {
  errors = {};
  config = {} as Config;
  isLoading = false;
  message = "";

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  /**
   * Get config
   * @returns array
   */
  get getConfig() {
    return this.config;
  }

  @Mutation
  [Mutations.SET_CONFIG](data) {
    this.config = data;
  }

  @Action
  [Actions.FETCH_CONFIG]() {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("configurations")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CONFIG, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
}
