import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/AdminJwtService";
import { Actions, Mutations } from "@/store/enums/AdminStoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Dashboard {
  total_users_count: number;
  new_users_count: number;
  public_events_count: number;
  private_events_count: number;
}

export interface DashboardInfo {
  errors: unknown;
  dashboard: Dashboard;
  isLoading: boolean;
  message: string;
  meta: unknown;
}

@Module({
  namespaced: true,
})
export default class AdminDashboardModule extends VuexModule implements DashboardInfo {
  errors = {};
  dashboard = {} as Dashboard;
  isLoading = false;
  message = "";
  meta = {};

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }

  /**
   * Get users
   * @returns array
   */
  get getDashboard() {
    return this.dashboard;
  }  

  /**
   * Get meta
   * @returns array
   */
  get getMeta() {
    return this.meta;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  @Mutation
  [Mutations.SET_DASHBOARD](dashboard) {
    this.dashboard = dashboard;
  }

  @Mutation
  [Mutations.SET_META](meta) {
    this.meta = meta;
  }

  @Action
  [Actions.FETCH_DASHBOARD]() {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("admin/dashboard")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_DASHBOARD, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
}
