import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/AdminJwtService";
import { Actions, Mutations } from "@/store/enums/AdminStoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Event {
  id: string;
  type: string;
  visibility: string;
  event_uid: string;
  name: string;
  description: string;
  image_url: string;
  start_at: string;
  end_at: string;
  max_people_limit: string;
  environment_id: string;
}

export interface EventUser {
  id: string;
  type: string;
  visibility: string;
  event_uid: string;
  name: string;
  description: string;
  image_url: string;
  start_at: string;
  end_at: string;
  max_people_limit: string;
  environment_id: string;
}

export interface EventsInfo {
  errors: unknown;
  events: Event[];
  eventUsers: EventUser[];
  isLoading: boolean;
  message: string;
  meta: unknown;
}

@Module({
  namespaced: true,
})
export default class AdminEventModule extends VuexModule implements EventsInfo {
  errors = {};
  events = {} as Event[];
  eventUsers = {} as EventUser[];
  isLoading = false;
  message = "";
  meta = {};

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }

  /**
   * Get events
   * @returns array
   */
  get getEvents() {
    return this.events;
  }

  /**
   * Get meta
   * @returns array
   */
  get getMeta() {
    return this.meta;
  }

  /**
   * Get events
   * @returns array
   */
   get getEventUsers() {
    return this.eventUsers;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  @Mutation
  [Mutations.SET_EVENTS](events) {
    this.events = events;
  }

  @Mutation
  [Mutations.SET_META](meta) {
    this.meta = meta;
  }

  @Mutation
  [Mutations.SET_EVENT_USERS](data) {
    this.eventUsers = data;
  }

  @Action
  [Actions.FETCH_EVENTS](payload) {
    return new Promise<void>((resolve, reject) => {
      // this.context.commit(Mutations.SET_EVENTS, []);
      // this.context.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading", {root: true});
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null,
          user_id: payload.userID ?? null,
          visibility: payload.visibility ?? null,
          online: payload.online ? 'true' : null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/events", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EVENTS, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        // this.context.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading", {root: true});
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_EVENT_USERS](id) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_EVENT_USERS, []);
      // this.context.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading", {root: true});
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("admin/events/"+id+"/users")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EVENT_USERS, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        // this.context.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading", {root: true});
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
}
