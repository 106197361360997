import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

import { Actions } from "@/store/enums/StoreEnums";
import { Actions as AdminActions } from "@/store/enums/AdminStoreEnums";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import "@/core/plugins/prismjs";
import "bootstrap";

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

// axios.interceptors.response.use(undefined, function (error) {
//     if (error) {
//         const originalRequest = error.config;
//         if (error.response.status === 401 && !originalRequest._retry) {

//             originalRequest._retry = true;
//             if(error.response.config.url.includes('admin/')){
//                 return store.dispatch(AdminActions.PURGE_AUTH).then(() => router.push({ name: "adminSignIn" }));
//             } else {
//                 return store.dispatch(Actions.PURGE_AUTH).then(() => router.push({ name: "signIn" }));
//             }
//         }
//     }
//     return Promise.reject(error);
// })
  

app.mount("#app");
