import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/AdminJwtService";
import { Actions, Mutations } from "@/store/enums/AdminStoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Session {
  id: string;
  session_uid: string;
  start_at: string;
  end_at: string;
  status: string;
  user: unknown;
}

export interface SessionsInfo {
  errors: unknown;
  sessions: Session[];
  isLoading: boolean;
  message: string;
  meta: unknown;
}

@Module({
  namespaced: true,
})
export default class AdminWorldSessionModule extends VuexModule implements SessionsInfo {
  errors = {};
  sessions = {} as Session[];
  isLoading = false;
  message = "";
  meta = {};

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }

  /**
   * Get sessions
   * @returns array
   */
  get getSessions() {
    return this.sessions;
  }  

  /**
   * Get meta
   * @returns array
   */
  get getMeta() {
    return this.meta;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  @Mutation
  [Mutations.SET_SESSIONS](sessions) {
    this.sessions = sessions;
  }

  @Mutation
  [Mutations.SET_META](meta) {
    this.meta = meta;
  }

  @Action
  [Actions.FETCH_SESSIONS](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null,
          live: payload.live ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/world/sessions", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SESSIONS, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

}
