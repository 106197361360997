import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AppModule from "@/store/modules/AppModule";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import MasterModule from "@/store/modules/MasterModule";
import EventModule from "@/store/modules/EventModule";
import PlanModule from "@/store/modules/PlanModule";

import AdminAuthModule from "@/store/modules/AdminAuthModule";
import AdminDashboardModule from "@/store/modules/AdminDashboardModule";
import AdminMasterModule from "@/store/modules/AdminMasterModule";
import AdminConfigurationModule from "@/store/modules/AdminConfigurationModule";
import AdminUserModule from "@/store/modules/AdminUserModule";
import AdminModeratorModule from "@/store/modules/AdminModeratorModule";
import AdminEventModule from "@/store/modules/AdminEventModule";
import AdminEventModerationModule from "@/store/modules/AdminEventModerationModule";
import AdminWorldSessionModule from "@/store/modules/AdminWorldSessionModule";

config.rawError = true;


const store = createStore({
  modules: {
    AppModule,
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    MasterModule,
    EventModule,
    PlanModule, 
    
    AdminAuthModule,
    AdminDashboardModule,
    AdminMasterModule,
    AdminConfigurationModule,
    AdminUserModule,
    AdminModeratorModule,
    AdminEventModule,
    AdminEventModerationModule,
    AdminWorldSessionModule,
  },
});

export default store;
