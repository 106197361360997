const ID_TOKEN_KEY = "token" as string;
const VERIFIED_KEY = "verified" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

/**
 * @description get verified form localStorage
 */
 export const getVerified = (): string | null => {
  return window.localStorage.getItem(VERIFIED_KEY);
};

/**
 * @description set verified into localStorage
 * @param verified: string
 */
 export const setVerified = (verified: string): void => {
  window.localStorage.setItem(VERIFIED_KEY, verified);
};

/**
 * @description remove verified form localStorage
 */
 export const destroyVerified = (): void => {
  window.localStorage.removeItem(VERIFIED_KEY);
};

export default { getToken, saveToken, destroyToken, getVerified, setVerified, destroyVerified };
