import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Timezone {
  id: string;
  code: string;
  name: string;
}

export interface Country {
  id: string;
  code: string;
  name: string;
}

export interface Userrole {
  id: string;
  role: string;
  name: string;
}

export interface Profession {
  id: string;
  name: string;
}

export interface EventCategory {
  id: string;
  name: string;
}

export interface EventEnvironment {
  id: string;
  name: string;
  code: string;
  image: string;
}

export interface UserRole {
  id: string;
  role: string;
  name: string;
}

export interface MasterInfo {
  errors: unknown;
  timezones: Timezone[];
  countries: Country[];
  userrole: Userrole[];
  professions: Profession[];
  eventCategories: EventCategory[];
  eventEnvironments: EventEnvironment[];
  userRoles: UserRole[];
  isLoading: boolean;
  message: string;
}

@Module({
  namespaced: true,
})
export default class MasterModule extends VuexModule implements MasterInfo {
  errors = {};
  timezones = {} as Timezone[];
  countries = {} as Country[];
  userrole = {} as Userrole[];
  professions = {} as Profession[];
  eventCategories = {} as EventCategory[];
  eventEnvironments = {} as EventEnvironment[];
  userRoles = {} as UserRole[];
  isLoading = false;
  message = "";

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  /**
   * Get timezones
   * @returns array
   */
  get getTimezones() {
    return this.timezones;
  }

  /**
   * Get countries
   * @returns array
   */
  get getCountries() {
    return this.countries;
  }

  

    /**
   * Get userrole
   * @returns array
   */
     get getUserrole() {
      return this.userrole;
    }
  

  /**
   * Get professions
   * @returns array
   */
   get getProfessions() {
    return this.professions;
  }

  /**
   * Get eventCategories
   * @returns array
   */
   get getEventCategories() {
    return this.eventCategories;
  }

  /**
   * Get eventEnvironments
   * @returns array
   */
   get getEventEnvironments() {
    return this.eventEnvironments;
  }

  /**
   * Get userRoles
   * @returns array
   */
   get getUserRoles() {
    return this.userRoles;
  }

  @Mutation
  [Mutations.SET_TIMEZONES](data) {
    this.timezones = data;
  }

  @Mutation
  [Mutations.SET_COUNTRIES](data) {
    this.countries = data;
  }

  @Mutation
  [Mutations.SET_PROFESSIONS](data) {
    this.professions = data;
  }

  @Mutation
  [Mutations.SET_EVENT_CATEGORIES](data) {
    this.eventCategories = data;
  }

  @Mutation
  [Mutations.SET_EVENT_ENVIRONMENTS](data) {
    this.eventEnvironments = data;
  }

  @Mutation
  [Mutations.SET_USER_ROLES](data) {
    this.userRoles = data;
  }

  @Action
  [Actions.FETCH_TIMEZONES]() {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("master/timezones")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_TIMEZONES, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_COUNTRIES]() {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("master/countries")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_COUNTRIES, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_PROFESSIONS]() {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("master/professions")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PROFESSIONS, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_EVENT_CATEGORIES]() {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("master/event-categories")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EVENT_CATEGORIES, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_EVENT_ENVIRONMENTS]() {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("master/event-environments")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_EVENT_ENVIRONMENTS, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_USER_ROLES]() {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("master/user-roles")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER_ROLES, data.data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
}
