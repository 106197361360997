import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Actions as AdminActions, Mutations as AdminMutations, Modules as AdminModules } from "@/store/enums/AdminStoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface User {
  name: string;
  email: string;
  photo: string;
  token: string;
  verified: boolean;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
  isVerified: boolean;
  socialProvider: string;
  socialProviderUID: string;
  socialToken: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  message = "";
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();
  isVerified = !!JwtService.getVerified();
  socialProvider = "";
  socialProviderUID = "";
  socialToken = "";

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user email
   * @returns boolean
   */
   get isUserVerified(): boolean {
    
    return this.isVerified;
  }
  

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
   
    return this.isAuthenticated;
  }

  
  
  get socialAuthProvider(): string {
    return this.socialProvider;
  }
  
  @Mutation
  [Mutations.SET_SOCIAL_AUTH_PROVIDER](value) {
    this.socialProvider = value;
  }

  get socialAuthProviderUID(): string {
    return this.socialProviderUID;
  }

  @Mutation
  [Mutations.SET_SOCIAL_AUTH_PROVIDER_UID](value) {
    this.socialProviderUID = value;
  }

  get socialAuthToken(): string {
    return this.socialToken;
  }

  @Mutation
  [Mutations.SET_SOCIAL_AUTH_TOKEN](value) {
    this.socialToken = value;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  /**
   * Get message
   * @returns array
   */
   get getSuccessMessage() {
    return this.message;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.data;
    this.errors = [];
    JwtService.saveToken(this.user.token);
    
    this.isVerified = this.user.verified;
    if(this.isVerified) JwtService.setVerified("true");
    //console.log("user"+this.user.verified);
    
    ApiService.setHeader();
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user.data;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
    JwtService.destroyVerified();
    this.isVerified = false;
  }

  @Action
  [Actions.LOGIN](credentials) {
    const params = {
      params: {
        ...credentials,
      },
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.post("login", credentials)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_AUTH, data);
          if (JwtService.getToken()) {
            ApiService.setAdminHeader();
            this.context.dispatch(AdminModules.AUTH + AdminActions.LOGOUT, null, {root:true})
            ApiService.setHeader();
          }
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action({rawError: true})
  [Actions.SOCIAL_AUTH](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("social/auth", credentials)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          this.context.commit(Mutations.SET_SOCIAL_AUTH_PROVIDER, credentials.provider);
          this.context.commit(Mutations.SET_SOCIAL_AUTH_PROVIDER_UID, credentials.provider_uid);
          this.context.commit(Mutations.SET_SOCIAL_AUTH_TOKEN, credentials.provider_token);
          reject(data);
        } else {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve(data);
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject(response.data);
      });
    });
  }

  @Action
  [Actions.SOCIAL_LINK_LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("social/link/existing", credentials)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SOCIAL_AUTH_PROVIDER, "");
          this.context.commit(Mutations.SET_SOCIAL_AUTH_PROVIDER_UID, "");
          this.context.commit(Mutations.SET_SOCIAL_AUTH_TOKEN, "");
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.SOCIAL_LINK_SIGNUP](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("social/link/new", credentials)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SOCIAL_AUTH_PROVIDER, "");
          this.context.commit(Mutations.SET_SOCIAL_AUTH_PROVIDER_UID, "");
          this.context.commit(Mutations.SET_SOCIAL_AUTH_TOKEN, "");
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("logout", {})
      .then(({ data }) => {
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.PURGE_AUTH);
      });
    });
  }

  @Action
  [Actions.PURGE_AUTH]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    
      return new Promise<void>((resolve, reject) => {
        ApiService.post("register", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          if(response.data.code == 'INVALID'){
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          } else {
            this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          }
          reject();
        });
      });
  }

  @Action
  [Actions.CAMPAIGNREGFORM](payload) {
    
      return new Promise<void>((resolve, reject) => {
        ApiService.post("event-registration", payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_AUTH, data);
          this.context.commit(Mutations.SET_ERROR, '');
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        })
        .catch(({ response }) => {
          // this.context.commit(Mutations.SET_ERROR, [response.data.errors]);
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject( response.data.errors);
        });
      });
  }
  @Action
  [Actions.CAMPAIGNREGFORMLOAD](credentials) {
    
      return new Promise<void>((resolve, reject) => {
        ApiService.post("event-reg-load", credentials)
        .then(({ data }) => {
          resolve(data.message);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, [response.data.errors]);
          reject();
        });
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("password/email", payload)
        .then(({ data }) => {
          // this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          if(response.data.code == 'INVALID'){
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          } else {
            this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          }
          reject();
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("password/reset", payload)
        .then(({ data }) => {
          if(data.status == false){
            this.context.commit(Mutations.SET_ERROR, [data.message]);
            reject();
          } else {
            resolve();
          }
        })
        .catch(({ response }) => {
          if(response.data.code == 'INVALID'){
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
          } else {
            this.context.commit(Mutations.SET_ERROR, [response.data.message]);
          }
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    return new Promise<void>((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        const params = {
          params: {
            token: JwtService.getToken(),
          },
        };
        ApiService.get("auth/verify").then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        }).catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
          reject();
        });
      } else {
        this.context.commit(Mutations.PURGE_AUTH);
        reject();
      }
    });
  }

  @Action
  [Actions.EMAIL_VERIFICATION_NOTIFICATION]() {
    return new Promise<void>((resolve, reject) => {
    ApiService.post("email/verification-notification", {})
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          resolve();
        }
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        reject();
      });
    });
  }

  @Action
  [Actions.FETCH_PROFILE]() {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("profile")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_PROFILE](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("profile", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.CHANGE_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("password", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_PAYMENT](payload) {
    //alert("check");
    return new Promise<void>((resolve, reject) => {
      ApiService.post("subscription/purchase", payload)
      .then(({ data }) => {
        console.log("tesultcheck",data.message);
         if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
         } else {
           this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve(data);
         }
      })
      .catch(({ response }) => {
        console.log(response);
        //console.log("tesultcheck",response.status);
      //  var text="Your card was declined";
        //  if(response.status == '500'){
        //   this.context.commit(Mutations.SET_ERROR,[response.data.message]);
        // } else {
        //   this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        // }
        reject();
      });
    });
  }




  // @Action
  // [Actions.UPDATE_USER](payload) {
  //   ApiService.setHeader();
  //   return new Promise<void>((resolve, reject) => {
  //     ApiService.post("update_user", payload)
  //       .then(({ data }) => {
  //         this.context.commit(Mutations.SET_USER, data);
  //         resolve();
  //       })
  //       .catch(({ response }) => {
  //         this.context.commit(Mutations.SET_ERROR, response.data.errors);
  //         reject();
  //       });
  //   });
  // }
}
