import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/AdminJwtService";
import { Actions, Mutations } from "@/store/enums/AdminStoreEnums";
import { Actions as MainActions, Mutations as MainMutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface User {
  role: string;
  name: string;
  email: string;
  photo: string;
  token: string;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module({
  namespaced: true,
})
export default class AdminAuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  message = "";
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  



  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  /**
   * Get message
   * @returns array
   */
   get getSuccessMessage() {
    return this.message;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user.data;
    this.errors = [];
    JwtService.saveToken(this.user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action({rawError: true})
  [Actions.LOGIN](credentials) {
    const params = {
      params: {
        ...credentials,
      },
    };
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/login", credentials)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_AUTH, data);
          if (JwtService.getToken()) {
            ApiService.setHeader();
            this.context.dispatch(MainActions.LOGOUT, null, {root:true})
            ApiService.setAdminHeader();
          }
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action({rawError: true})
  [Actions.LOGOUT]() {
    ApiService.setAdminHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/logout", {})
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_AUTH);
        ApiService.setHeader();
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.PURGE_AUTH]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    return new Promise<void>((resolve, reject) => {
      if (JwtService.getToken()) {
        ApiService.setAdminHeader();
        const params = {
          params: {
            token: JwtService.getToken(),
          },
        };
        ApiService.get("admin/auth/verify")
          .then(({ data }) => {
            this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          })
          .catch(({ response }) => {
            this.context.commit(Mutations.SET_ERROR, response.data.errors);
            this.context.commit(Mutations.PURGE_AUTH);
            reject();
          });
      } else {
        this.context.commit(Mutations.PURGE_AUTH);
        reject();
      }
    });
  }
  
  @Action
  [Actions.CHANGE_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/password", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }


}
