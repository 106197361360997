import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { Actions as AdminActions, Modules as AdminModules } from "@/store/enums/AdminStoreEnums";
import layoutConfig from "@/core/config/DefaultLayoutConfig";
import publicLayoutConfig from "@/core/config/DefaultPublicLayoutConfig";
import JwtService from "@/core/services/JwtService";
import AdminJwtService from "@/core/services/AdminJwtService";
import { useRouter, useRoute } from "vue-router";

const guestRequired = (to, from, next) => {
  if (!JwtService.getToken()) {
    next()
    return
  }
  next({
    name: 'dashboard',
  })
}

const authRequired = (to, from, next) => {
  if(JwtService.getToken() && JwtService.getVerified() == "true"){
    next()
    return
  }
  if(JwtService.getToken() && JwtService.getVerified() != "true"){ 
    next({
      name: 'verification',
    })
    return
  }
  next({
    name: 'signIn',
    query: { redirect: to.fullPath },
  })
}

const unVerifiedRequired = (to, from, next) => {
  if(JwtService.getToken() && JwtService.getVerified() != "true"){ 
    next()
    return
  }   
  if(JwtService.getToken() && JwtService.getVerified() == "true"){ 
    next({
      name: 'dashboard',
    })
    return
  }
  next({
    name: 'signIn',
    query: { redirect: to.fullPath },
  })
}

const adminGuestRequired = (to, from, next) => {
  if (!AdminJwtService.getToken()) {
    next()
    return
  }
  next({
    name: 'adminDashboard',
  })
}

const adminAuthRequired = (to, from, next) => {
  if(AdminJwtService.getToken()){
    next()
    return
  }
  next({
    name: 'adminSignIn',
    query: { redirect: to.fullPath },
  })
}

const route = useRoute();
//const emailVerified = ref("");
//emailVerified.value = route.query.email_verified as string;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    
    // meta: { publicLayout: true },
    component: () => import("@/layout/PublicLayout.vue"),
    children: [
      {
        path: "/test",
        name: "test",
        // meta: { guestRequired: true },
        // beforeEnter: guestRequired,
        meta: { publicLayout: true },
        component: () =>
          import("@/views/CampaignRegForm.vue"),
      },
      {
        path: "/",
        name: "home",
        // meta: { publicLayout: true },
        component: () => import("@/views/Events.vue"),
      },
      {
        path: "download",
        name: "download",
        component: () => import("@/views/Download.vue"),
      },
      {
        path: "plans",
        name: "plans",
        component: () => import("@/views/Plans.vue"),
      },
      {
        path: "plans/:code/checkout",
        name: "checkout",
        // meta: { authRequired: true },
        beforeEnter: authRequired,
        component: () => import("@/views/Checkout.vue"),
      },
      {
        path: "order/success",
        name: "orderSuccess",
        component: () => import("@/views/OrderSuccess.vue"),
      },
      {
        path: "order/cancel",
        name: "orderCancel",
        component: () => import("@/views/OrderCancel.vue"),
      },
    ],
  },
  {
    path: "/event-registration",
    name: "eventRegistration",
    // meta: { guestRequired: true },
    // beforeEnter: guestRequired,
    // meta: { publicLayout: true },
    component: () =>
      import("@/views/CampaignRegForm.vue"),
  },

  {
    path: "/account",
    // meta: { authRequired: true },
    beforeEnter: authRequired,
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        redirect: { name: 'profile' },
        beforeEnter: authRequired,
         //component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "create-event",
        redirect: { name: 'createEvent' },
        beforeEnter: authRequired,
        component: () => import("@/views/CreateEvent.vue"),
        children: [
          {
            path: "choose-environment",
            name: "createEvent",
            beforeEnter: authRequired,
            component: () => import("@/views/CreateEventChooseEnvironment.vue"),
          },
          {
            path: "complete",
            name: "createEventComplete",
            beforeEnter: authRequired,
            component: () => import("@/views/CreateEventComplete.vue"),
          },
        ],
      },
      {
        path: "settings",
        name: "settings",
        redirect: { name: 'changePassword' },
        beforeEnter: authRequired,
        component: () => import("@/views/Settings.vue"),
        children: [
          {
            path: "change-password",
            name: "changePassword",
            beforeEnter: authRequired,
            component: () => import("@/views/ChangePassword.vue"),
          },
        ],
      },
      {
        path: "",
        beforeEnter: authRequired,
        component: () => import("@/views/Account.vue"),
        children: [
          {
            path: "my-events",
            name: "myEvents",
            beforeEnter: authRequired,
            component: () => import("@/views/MyEvents.vue"),
          },
          {
            path: "my-events/history",
            name: "myEventsHistory",
            beforeEnter: authRequired,
            component: () => import("@/views/MyEventsHistory.vue"),
          },
          {
            path: "registered-events",
            name: "registeredEvents",
            beforeEnter: authRequired,
            component: () => import("@/views/MyEvents.vue"),
          },
          {
            path: "profile",
            name: "profile",
            beforeEnter: authRequired,
            component: () => import("@/views/Profile.vue"),
          },  
          {
            path: "edit-profile",
            name: "editProfile",
            beforeEnter: authRequired,
            component: () => import("@/views/EditProfile.vue"),
          }, 
          {
            path: "subscription",
            name: "subscription",
            beforeEnter: authRequired,
            component: () => import("@/views/Subscription.vue"),
          },
          {
            path: "subscription/portal",
            name: "subscriptionPortal",
            beforeEnter: authRequired,
            component: () => import("@/views/SubscriptionPortal.vue"),
          },
        ],
      },     
    ],
  },




  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/social-link",
        name: "socialLink",
        // meta: { guestRequired: true },
        beforeEnter: guestRequired,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SocialLink.vue"),
      },
      {
        path: "/sign-in",
        name: "signIn",
        // meta: { guestRequired: true },
        beforeEnter: guestRequired,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "signUp",
        // meta: { guestRequired: true },
        beforeEnter: guestRequired,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
     
     
      {
        path: "/forgot-password",
        name: "forgotPassword",
        // meta: { guestRequired: true },
        beforeEnter: guestRequired,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/ForgotPassword.vue"),
      },
      {
        path: "/reset-password",
        name: "resetPassword",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/ResetPassword.vue"),
      },
      {
        path: "/verification",
        name: "verification",
        // meta: { unVerifiedRequired: true },
        beforeEnter: unVerifiedRequired,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/Verification.vue"),
      },
      {
        path: "/verify",
        name: "verifyEmail",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/VerifyEmail.vue"),
      },
      {
        path: "payment/success",
        name: "success",
        // meta: { publicLayout: true },
        component: () =>  import("@/views/crafted/authentication/basic-flow/Success.vue"),
      },
      // {
      //   path: "/plans",
      //   name: "plans",
      //   component: () =>
      //    import("@/views/crafted/authentication/basic-flow/Plans.vue"),
      // },

    ],
  },
  {
    path: "/admin",
    redirect: { name: 'adminSignIn' },
  },
  {
    path: "/admin",
    // meta: { adminPanel: true },
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "sign-in",
        name: "adminSignIn",
        // meta: { guestRequired: true },
        beforeEnter: adminGuestRequired,
        component: () =>
          import("@/views/crafted/authentication/basic-flow/admin/SignIn.vue"),
      },
    ],
  },
  {
    path: "/admin",
    // meta: { adminPanel: true, authRequired: true },
    beforeEnter: adminAuthRequired,
    component: () => import("@/layout/AdminLayout.vue"),
    children: [
      {
        path: "dashboard",
        name: "adminDashboard",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/Dashboard.vue"),
      },
      {
        path: "events",
        name: "adminEvents",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/Events.vue"),
      },
      {
        path: "users",
        name: "adminUsers",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/Users.vue"),
      },
      {
        path: "users/:userID/events",
        name: "adminUserEvents",
        component: () => import("@/views/admin/Events.vue"),
      },
      {
        path: "moderators",
        name: "adminModerators",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/moderators/Index.vue"),
      },
      {
        path: "world/sessions",
        name: "worldSessions",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/WorldSessions.vue"),
      },
      {
        path: "event-moderation",
        name: "adminEventModeration",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/EventModeration.vue"),
      },
      {
        path: "master/countries",
        name: "adminCountries",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/master/Countries.vue"),
      },
      {
        path: "master/professions",
        name: "adminProfessions",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/master/Professions.vue"),
      },
      {
        path: "master/timezones",
        name: "adminTimezones",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/master/Timezones.vue"),
      },
      {
        path: "master/event-categories",
        name: "adminEventCategories",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/master/EventCategories.vue"),
      },
      {
        path: "master/event-environments",
        name: "adminEventEnvironments",
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/master/EventEnvironments.vue"),
      },
      {
        path: "master/spaces",
        name: "adminSpaces",
        component: () => import("@/views/admin/master/Spaces.vue"),
      },
      {
        path: "master/zones",
        name: "adminZones",
        component: () => import("@/views/admin/master/Zones.vue"),
      },
      {
        path: "master/zones/:zoneID/banners",
        name: "adminBanners",
        component: () => import("@/views/admin/master/Banners.vue"),
      },
      {
        path: "master/resources",
        name: "adminResources",
        component: () => import("@/views/admin/master/Resources.vue"),
      },
      {
        path: "master/portals",
        name: "adminPortals",
        component: () => import("@/views/admin/master/Portals.vue"),
      },
      {
        path: "configurations",
        name: "adminConfigurations",
        component: () => import("@/views/admin/Configurations.vue"),
      },
      {
        path: "settings",
        name: "adminSettings",
        redirect: { name: 'adminChangePassword' },
        beforeEnter: adminAuthRequired,
        component: () => import("@/views/admin/Settings.vue"),
        children: [
          {
            path: "change-password",
            name: "adminChangePassword",
            beforeEnter: adminAuthRequired,
            component: () => import("@/views/admin/ChangePassword.vue"),
          },
        ],
      },
      {
        path: "campaigns-reg",
        name: "campaignsReg",
        component: () => import("@/views/admin/CampaignsReg.vue"),
      },
      {
        path: "campaigns-mail-templates",
        name: "campaignMailTemplates",
        component: () => import("@/views/admin/CampaignsMailTemplates.vue"),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // if (to.matched.some(record => record.meta.publicLayout) && !from.matched.some(record => record.meta.publicLayout)) {
  //   store.commit(Mutations.REMOVE_BODY_CLASSNAMES);
  //   store.commit(Mutations.SET_LAYOUT_CONFIG, publicLayoutConfig);
  // } else if (!to.matched.some(record => record.meta.publicLayout) && from.matched.some(record => record.meta.publicLayout)) {
  //   store.commit(Mutations.REMOVE_BODY_CLASSNAMES);
  //   store.commit(Mutations.SET_LAYOUT_CONFIG, layoutConfig);
  // }

  // store.dispatch(Actions.VERIFY_AUTH);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  
  next()
  return
});

export default router;
