import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/AdminJwtService";
import { Actions, Mutations } from "@/store/enums/AdminStoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface User {
  id: string;
  name: string;
  email: string;
  photo: string;
  phone: string;
  timezone: string;
}

export interface UsersInfo {
  errors: unknown;
  users: User[];
  isLoading: boolean;
  message: string;
  meta: unknown;
}

@Module({
  namespaced: true,
})
export default class AdminUserModule extends VuexModule implements UsersInfo {
  errors = {};
  users = {} as User[];
  isLoading = false;
  message = "";
  meta = {};

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }

  /**
   * Get users
   * @returns array
   */
  get getUsers() {
    return this.users;
  }  

  /**
   * Get meta
   * @returns array
   */
  get getMeta() {
    return this.meta;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  @Mutation
  [Mutations.SET_USERS](users) {
    this.users = users;
  }

  @Mutation
  [Mutations.SET_META](meta) {
    this.meta = meta;
  }

  @Action
  [Actions.FETCH_USERS](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null,
          latest: payload.latest ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/users", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USERS, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.STORE_USER](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/users", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_USER_ROLE](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/users/"+data.user_id+"/world-role", data.payload)
     
      .then(({ data }) => {
        // if(data.status == false){
        //   this.context.commit(Mutations.SET_ERROR, [data.message]);
        //   reject();
        // } else {
        //   this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
        //   resolve();
        // }
        resolve(data);
      })
      .catch(({ response }) => {
        // if(response.data.code == 'INVALID'){
        //   this.context.commit(Mutations.SET_ERROR, response.data.errors);
        // } else {
        //   this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        // }
        reject();
      });
    });
  }

  @Action
  [Actions.SET_INTERNAL_SUBSCRIPTION](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/users/"+data.user_id+"/internal-subscription", data.payload)
     
      .then(({ data }) => {
        // if(data.status == false){
        //   this.context.commit(Mutations.SET_ERROR, [data.message]);
        //   reject();
        // } else {
        //   this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
        //   resolve();
        // }
        resolve(data);
      })
      .catch(({ response }) => {
        // if(response.data.code == 'INVALID'){
        //   this.context.commit(Mutations.SET_ERROR, response.data.errors);
        // } else {
        //   this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        // }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_USER_STATUS](data) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/users/"+data.user_id+"/status", data.payload)
     
      .then(({ data }) => {
        // if(data.status == false){
        //   this.context.commit(Mutations.SET_ERROR, [data.message]);
        //   reject();
        // } else {
        //   this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
        //   resolve();
        // }
        resolve(data);
      })
      .catch(({ response }) => {
        // if(response.data.code == 'INVALID'){
        //   this.context.commit(Mutations.SET_ERROR, response.data.errors);
        // } else {
        //   this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        // }
        reject();
      });
    });
  }

  @Action
  [Actions.CANCEL_USER_SUBSCRIPTION](payload) {
   // new Promise((resolve, reject) => resolve('success')).then(data => console.log(data));

    return new Promise<void>((resolve, reject) => {
      // this.context.commit(Mutations.SET_EVENTS, []);
      // this.context.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading", {root: true});
      this.context.commit(Mutations.SET_IS_LOADING, true);
     
      ApiService.post("admin/users/"+payload.user_id+"/subscription/cancellation", payload.values)
      
      .then(({ data }) => {
        // if(data.status == false){
        //   this.context.commit(Mutations.SET_ERROR, [data.message]);
        //   reject();
        // } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
        //   resolve();
        // }
        resolve(data);
      })
      .catch(({ response }) => {
        // if(response.data.code == 'INVALID'){
        //   this.context.commit(Mutations.SET_ERROR, response.data.errors);
        // } else {
        //   this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        // }
        reject();
      });
    });
  }



}
