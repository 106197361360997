import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Plans {
  id: string;
  code: string;
  name: string;
  description: string;
  price:string; 
}

export interface Checkout {
  id: string;
  url: string;
  success_url: string;
  cancel_url: string;
}

export interface MySubscription {
  id: string;
  name: string;
}

export interface PlansInfo {
  errors: unknown;
  plans: Plans[];
  checkout: Checkout;
  mySubscription: MySubscription;
  mySubscriptionPortalURL: string;
  isLoading: boolean;
  message: string;
}

@Module({
  namespaced: true,
})
export default class PlanModule extends VuexModule implements PlansInfo {
  errors = {};
  plans = {} as Plans[];
  checkout = {} as Checkout;
  mySubscription = {} as MySubscription;
  mySubscriptionPortalURL = "";
  isLoading = false;
  message = "";

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }

  /**
   * Get Plans
   * @returns array
   */
  get getPlans() {
    return this.plans;
  }

  /**
   * Get Checkout
   * @returns array
   */
  get getCheckout() {
    return this.checkout;
  }

  /**
   * Get mySubscription
   * @returns array
   */
  get getMySubscription() {
    return this.mySubscription;
  }

  /**
   * Get mySubscription
   * @returns array
   */
   get getMySubscriptionPortalURL() {
    return this.mySubscriptionPortalURL;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  @Mutation
  [Mutations.SET_PLANS](plans) {
    this.plans = plans.data;
  }

  @Mutation
  [Mutations.SET_CHECKOUT](checkout) {
    this.checkout = checkout.data;
  }

  @Mutation
  [Mutations.SET_MY_SUBSCRIPTION](mySubscription) {
    this.mySubscription = mySubscription.data ?? null;
  }

  @Mutation
  [Mutations.SET_MY_SUBSCRIPTION_PORTAL_URL](url) {
    this.mySubscriptionPortalURL = url ?? null;
  }

  @Action
  [Actions.FETCH_PLANS](currencyType) {
    return new Promise<void>((resolve, reject) => {
      // this.context.commit(Mutations.SET_EVENTS, []);
      // this.context.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading", {root: true});
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("subscription/plans?type="+currencyType)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PLANS, data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        // this.context.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading", {root: true});
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_CHECKOUT](payload) {
    const planCode = payload.plan_code;
    return new Promise<void>((resolve, reject) => {
      // this.context.commit(Mutations.SET_EVENTS, []);
      // this.context.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading", {root: true});
      this.context.commit(Mutations.SET_IS_LOADING, true);
      ApiService.get("subscription/plans/"+planCode+"/checkout")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CHECKOUT, data);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        // this.context.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading", {root: true});
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }


  @Action
  [Actions.FETCH_MY_SUBSCRIPTION]() {
   // new Promise((resolve, reject) => resolve('success')).then(data => console.log(data));

    return new Promise<void>((resolve, reject) => {
      // this.context.commit(Mutations.SET_EVENTS, []);
      // this.context.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading", {root: true});
      this.context.commit(Mutations.SET_IS_LOADING, true);
     
      ApiService.get("my-subscription")
      .then(({ data }) => {
       this.context.commit(Mutations.SET_MY_SUBSCRIPTION, data);
        resolve(data);
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        // this.context.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading", {root: true});
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }


  @Action
  [Actions.CANCEL_MY_SUBSCRIPTION](payload) {
   // new Promise((resolve, reject) => resolve('success')).then(data => console.log(data));

    return new Promise<void>((resolve, reject) => {
      // this.context.commit(Mutations.SET_EVENTS, []);
      // this.context.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading", {root: true});
      this.context.commit(Mutations.SET_IS_LOADING, true);
     
      ApiService.post("my-subscription/cancellation", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        // this.context.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading", {root: true});
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }

  @Action
  [Actions.FETCH_MY_SUBSCRIPTION_PORTAL_URL]() {
   // new Promise((resolve, reject) => resolve('success')).then(data => console.log(data));

    return new Promise<void>((resolve, reject) => {
      // this.context.commit(Mutations.SET_EVENTS, []);
      // this.context.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading", {root: true});
      this.context.commit(Mutations.SET_IS_LOADING, true);
     
      ApiService.get("my-subscription/customer-portal")
      .then(({ data }) => {
       this.context.commit(Mutations.SET_MY_SUBSCRIPTION_PORTAL_URL, data.data.portal_url);
        resolve(data);
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        // this.context.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading", {root: true});
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }


 

  
}
