enum Actions {
  LOGIN = "login",
  LOGOUT = "logout",
  VERIFY_AUTH = "verifyAuth",
  PURGE_AUTH = "purgeAuth",
  
  FETCH_DASHBOARD = "fetchDashboard",
  CHANGE_PASSWORD = "changePassword",

  FETCH_USERS = "fetchUsers",
  STORE_USER = "storeUser",
  UPDATE_USER_ROLE ="updateUserRole",
  SET_INTERNAL_SUBSCRIPTION = "setInternalSubscription",
  UPDATE_USER_STATUS = "updateUserStatus",
  CANCEL_USER_SUBSCRIPTION = "cancelUserSubscription",
  
  FETCH_MODERATORS = "fetchModerators",
  STORE_MODERATOR = "storeModerator",
  GET_MODERATOR = "fetchModerator",
  UPDATE_MODERATOR = "updateModerator",
  DELETE_MODERATOR = "deleteModerator",

  FETCH_EVENTS = "fetchEvents",
  FETCH_EVENT_USERS = "fetchEventUsers",
  FETCH_SESSIONS = "fetchSessions",

  FETCH_EVENTS_FOR_MODERATION = "fetchEventsForModeration",
  APPROVE_EVENT_FOR_MODERATION = "approveEventForModeration",
  REJECT_EVENT_FOR_MODERATION = "rejectEventForModeration",
  
  FETCH_TIMEZONES = "fetchTimezones",
  FETCH_COUNTRIES = "fetchCountries",

  FETCH_PROFESSIONS = "fetchProfessions",
  STORE_PROFESSION = "storeProfession",
  UPDATE_PROFESSION = "updateProfession",
  DELETE_PROFESSION = "deleteProfession",

  FETCH_EVENT_CATEGORIES = "fetchEventCategories",
  STORE_EVENT_CATEGORY = "storeEventCategory",
  GET_EVENT_CATEGORY = "fetchEventCategory",
  UPDATE_EVENT_CATEGORY = "updateEventCategory",
  DELETE_EVENT_CATEGORY = "deleteEventCategory",

  FETCH_EVENT_ENVIRONMENTS = "fetchEventEnvironments",
  STORE_EVENT_ENVIRONMENT = "storeEventEnvironment",
  UPDATE_EVENT_ENVIRONMENT = "updateEventEnvironment",
  FETCH_USER_ROLES = "fetchUserRoles",
  FETCH_ALL_SPACES = "fetchAllSpaces",
  FETCH_SPACES = "fetchSpaces",
  STORE_SPACE = "storeSpace",
  GET_SPACE = "fetchSpace",
  UPDATE_SPACE = "updateSpace",
  DELETE_SPACE = "deleteSpace",
  FETCH_ALL_ZONES = "fetchAllZones",
  FETCH_ZONES = "fetchZones",
  STORE_ZONE = "storeZone",
  GET_ZONE = "fetchZone",
  UPDATE_ZONE = "updateZone",
  DELETE_ZONE = "deleteZone",
  FETCH_ALL_BANNERS = "fetchAllBanners",
  FETCH_BANNERS = "fetchBanners",
  STORE_BANNER = "storeBanner",
  GET_BANNER = "fetchBanner",
  UPDATE_BANNER = "updateBanner",
  DELETE_BANNER = "deleteBanner",
  FETCH_RESOURCES = "fetchResources",
  STORE_RESOURCE = "storeResource",
  UPDATE_RESOURCE = "updateResource",
  DELETE_RESOURCE = "deleteResource",
  STORE_RESOURCE_BANNER = "storeResourceBanner",
  DELETE_RESOURCE_BANNER = "deleteResourceBanner",
  FETCH_CAMPAIGNS_REG = "fetchCampaignsReg",
  FETCH_MAIL_TEMPLATES = "fetchMailTemplates",
  UPDATE_MAIL_TEMPLATE = "updateMailTemplate",
  FETCH_CONFIGURATIONS = "fetchConfigurations",
  UPDATE_CONFIGURATION = "updateConfiguration",
  FETCH_ALL_PORTALS = "fetchAllPortals",
  FETCH_PORTALS = "fetchPortals",
  STORE_PORTAL = "storePortal",
  GET_PORTAL = "fetchPortal",
  UPDATE_PORTAL = "updatePortal",
  DELETE_PORTAL = "deletePortal",
  STORE_PORTAL_EVENT = "storePortalEvent",
  DELETE_PORTAL_EVENT = "deletePortalEvent",
  GET_MAIL_TEMPLATE = "getMailTemplate",

}

enum Mutations {
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_ERROR = "setError",
  SET_META = "setMeta",
  SET_SUCCESS_MESSAGE = "setSuccessMessage",
  SET_IS_LOADING = "setIsLoading",
  
  SET_DASHBOARD = "setDashboard",

  SET_USERS = "setUsers",
  SET_EVENTS = "setEvents",
  SET_EVENT_USERS = "setEventUsers",
  SET_SESSIONS = "setSessions",
  
  SET_MODERATORS = "setModerators",
  SET_MODERATOR = "setModerator",

  SET_EVENTS_FOR_MODERATION = "setEventsForModeration",
  
  SET_TIMEZONES = "setTimezones",
  SET_COUNTRIES = "setCountries",
  SET_USER_ROLES = "setUserRoles",

  SET_PROFESSIONS = "setProfessions",
  SET_PROFESSION = "setProfession",

  SET_EVENT_CATEGORIES = "setEventCategories",
  SET_EVENT_CATEGORY = "setEventCategory",

  SET_EVENT_ENVIRONMENTS = "setEventEnvironments",
  SET_SPACES = "setSpaces",
  SET_SPACE = "setSpace",
  SET_ZONES = "setZones",
  SET_ZONE = "setZone",
  SET_BANNERS = "setBanners",
  SET_RESOURCES = "setResources",
  SET_CAMPAIGNS_REG = "setCampaignReg",
  SET_MAIL_TEMPLATE = "setMailTemplate",
  SET_CONFIGURATIONS = "setConfigurations",
  SET_PORTALS = "setPortals",
  SET_PORTAL = "setPortal",

}

enum Modules {
  AUTH = "AdminAuthModule/",
  DASHBOARD = "AdminDashboardModule/",
  USER = "AdminUserModule/",
  MODERATOR = "AdminModeratorModule/",
  EVENT = "AdminEventModule/",
  EVENT_MODERATION = "AdminEventModerationModule/",
  WORLD_SESSION = "AdminWorldSessionModule/",
  MASTER = "AdminMasterModule/",
  CONFIGURATION = "AdminConfigurationModule/",
}

export { Actions, Mutations, Modules};
