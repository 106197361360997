import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/AdminJwtService";
import { Actions, Mutations } from "@/store/enums/AdminStoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { AxiosRequestConfig } from "axios";

export interface Moderator {
  id: string;
  name: string;
  email: string;
  photo: string;
  status: string;
}

export interface ModeratorsInfo {
  errors: unknown;
  moderators: Moderator[];
  moderator: Moderator;
  isLoading: boolean;
  message: string;
  meta: unknown;
}

@Module({
  namespaced: true,
})
export default class AdminModeratorModule extends VuexModule implements ModeratorsInfo {
  errors = {};
  moderators = {} as Moderator[];
  moderator = {} as Moderator;
  isLoading = false;
  message = "";
  meta = {};

  /**
   * Get errors
   * @returns array
   */
  get getIsLoading() {
    return this.isLoading;
  }

  /**
   * Get message
   * @returns array
   */
  get getSuccessMessage() {
    return this.message;
  }

  /**
   * Get errors
   * @returns array
   */
   get getErrors() {
    return this.errors;
  }

  /**
   * Get moderators
   * @returns array
   */
  get getModerators() {
    return this.moderators;
  }  
  /**
   * Get getModerator
   * @returns array
   */
   get getModerator() {
    return this.moderator;
  }

  /**
   * Get meta
   * @returns array
   */
  get getMeta() {
    return this.meta;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_SUCCESS_MESSAGE](message) {
    this.message = message;
  }

  @Mutation
  [Mutations.SET_IS_LOADING](type) {
    this.isLoading = type;
  }

  @Mutation
  [Mutations.SET_MODERATORS](moderators) {
    this.moderators = moderators;
  }

  @Mutation
  [Mutations.SET_MODERATOR](data) {
    this.moderator = data;
  }

  @Mutation
  [Mutations.SET_META](meta) {
    this.meta = meta;
  }

  @Action
  [Actions.FETCH_MODERATORS](payload) {
    return new Promise<void>((resolve, reject) => {
      this.context.commit(Mutations.SET_IS_LOADING, true);
      const params = {
        params: {
          search: payload.search ?? null
        },
      };
      const url = payload.url ? payload.url : null;
      ApiService.query(url ?? "admin/moderators", params as AxiosRequestConfig)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_MODERATORS, data.data);
        this.context.commit(Mutations.SET_META, data.meta);
        resolve();
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      }).finally(() => {
        this.context.commit(Mutations.SET_IS_LOADING, false);
      });
    });
  }
  
  @Action
  [Actions.STORE_MODERATOR](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("admin/moderators", payload)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.GET_MODERATOR](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.get("admin/moderators/"+payload.id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_MODERATOR, data.data);
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.UPDATE_MODERATOR](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.put("admin/moderators/"+payload.id, payload.values)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

  @Action
  [Actions.DELETE_MODERATOR](id) {
    return new Promise<void>((resolve, reject) => {
      ApiService.delete("admin/moderators/"+id)
      .then(({ data }) => {
        if(data.status == false){
          this.context.commit(Mutations.SET_ERROR, [data.message]);
          reject();
        } else {
          this.context.commit(Mutations.SET_SUCCESS_MESSAGE, data.message);
          resolve();
        }
      })
      .catch(({ response }) => {
        if(response.data.code == 'INVALID'){
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
        } else {
          this.context.commit(Mutations.SET_ERROR, [response.data.message]);
        }
        reject();
      });
    });
  }

}
