
import { defineComponent, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Mutations } from "@/store/enums/StoreEnums";
import { initializeComponents } from "@/core/plugins/keenthemes";
import { Modules, Actions } from "@/store/enums/StoreEnums";
import { Actions as AdminActions, Modules as AdminModules } from "@/store/enums/AdminStoreEnums";

export default defineComponent({
  name: "app",
  setup() {
    const store = useStore();
    const router = useRouter();

    if(store.getters.isUserAuthenticated) {
      store.dispatch(Actions.VERIFY_AUTH).catch(() => {
        router.push({ name: "signIn" });
      });
    }
    if(store.getters[AdminModules.AUTH + "isUserAuthenticated"]) {
      store.dispatch(AdminModules.AUTH + AdminActions.VERIFY_AUTH).catch(() => {
        router.push({ name: "adminSignIn" });
      });
    }
    store.dispatch(Modules.APP + Actions.FETCH_CONFIG);
    store.dispatch(Modules.MASTER + Actions.FETCH_TIMEZONES);
    store.dispatch(Modules.MASTER + Actions.FETCH_COUNTRIES);
    store.dispatch(Modules.MASTER + Actions.FETCH_PROFESSIONS);
    store.dispatch(Modules.MASTER + Actions.FETCH_EVENT_CATEGORIES);
    store.dispatch(Modules.MASTER + Actions.FETCH_EVENT_ENVIRONMENTS);
    store.dispatch(Modules.MASTER + Actions.FETCH_USER_ROLES);

    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      // store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);

      nextTick(() => {
        initializeComponents();
      });
    });
  },
});
